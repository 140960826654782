<template>
  <div id="Header">
    <div class="headers" :class="$store.state.lang == en ? 'english' : ''">
      <div class="logo">
        <img src="https://newstore.vynior.com/hthm1.png" alt="" />
      </div>
      <!-- 页面 -->
      <div class="page">
        <div class="fistPage" :class="focus[0] ? 'isactive' : ''" @click="IsActive(0)">
          <span>{{ $t(`message.Header.one`) }}</span>
        </div>
        <div class="project" :class="focus[1] ? 'isactive' : ''" @click="IsActive(1)">
          <span>{{ $t(`message.Header.two`) }}</span>
        </div>
        <div class="singleAnalyse" @click="IsActive(2)" :class="focus[2] ? 'isactive' : ''">
          <span>{{ $t(`message.Header.three`) }}</span>
          <div class="father_box">
            <div class="choose_tow">
              <div class="choose_tow2">
                <div class="choose_tow3">
                  <span @click="IsActive(2)" class="choose_1" :class="info == '2' ? 'choose_1s' : 'choose_1'">{{ $t(`message.Header.single`) }}</span>
                  <span @click.stop="IsActive(5)" :class="info == '5' ? 'choose_1s' : 'choose_1'" class="choose_2">{{
                    $t(`message.Header.compare`)
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="cAnalyse" :class="focus[3] ? 'isactive' : ''">
          <span @click="IsActive(3)">{{ $t(`message.Header.four`) }}</span>
          <div class="father_box">
            <div class="choose_tow">
              <div class="choose_tow2">
                <div class="choose_tow3">
                  <span class="choose_1" :class="info == '3' ? 'choose_1s' : 'choose_1'" @click="IsActive(3)">{{
                    $t(`message.Header.profile`)
                  }}</span>
                  <span class="choose_2" :class="info == '9' ? 'choose_1s' : 'choose_2'" @click="IsActive(9)">{{
                    $t(`message.Header.classification`)
                  }}</span>
                  <span class="choose_3" :class="info == '7' ? 'choose_1s' : 'choose_3'" @click="IsActive(7)">{{
                    $t(`message.Header.Feature`)
                  }}</span>
                  <span class="choose_4" :class="info == '8' ? 'choose_1s' : 'choose_4'" @click="IsActive(8)">{{
                    $t(`message.Header.significance`)
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="history" :class="focus[4] ? 'isactive' : ''" @click="IsActive(4)">
          <span>{{ $t(`message.Header.five`) }}</span>
        </div>
      </div>
      <!-- 用户信息 -->
      <div class="user">
        <div class="helps" @click="HelpOperation = true" v-if="0">
          <span style="margin-right: 16px">操作指引</span>
        </div>
        <div class="help" @click="helpDialogRole = true">
          <span>{{ $t(`message.Header.six`) }}</span>
        </div>
        <!-- 用户名字 -->
        <div class="name">
          <span>{{ userName }}</span>
        </div>

        <div class="userImg">
          <img src="https://newstore.vynior.com/header1.png" alt="" />

          <!-- 头像悬浮 出现个人菜单 -->
          <div class="list_set">
            <ul>
              <li class="top_1" @click="change_mm">{{ $t(`message.Header.Change`) }}</li>
              <!-- <li @click="langChange(cn)" v-show="changeLanguage == true">切换中文</li>
              <li @click="langChange(en)" v-show="changeLanguage == false">切换英文</li> -->
              <li @click="go_quit">{{ $t(`message.Header.Switch`) }}</li>
              <li @click="go_quit">{{ $t(`message.Header.Log`) }}</li>
            </ul>
          </div>
        </div>
        <div class="ChangeLanguage">
          <span @click="langChange(cn)" v-show="$store.state.lang == 'en'">中文</span>
          <span @click="langChange(en)" v-show="$store.state.lang == 'cn'">En</span>
        </div>
      </div>
      <!-- 修改密码的页面 -->
      <div class="change_psw" v-show="change_">
        <div class="content">
          <div class="line1">
            <span>{{ $t(`message.Header.ResetPassword`) }}</span>
            <img src="https://newstore.vynior.com/can1.png" alt="" @click="change_ = 0" />
          </div>
          <!-- <div class="line2">为了您的账号安全，首次登录请修改初始密码</div> -->
          <div class="line7">
            <span>{{ $t(`message.Header.OldPassword`) }}</span>
            <el-input
              :placeholder="$t(`message.Header.EnterOldPWD`)"
              v-model="old_psw"
              :type="ifeys1 ? 'password' : 'text'"
              class="input_password"
              @blur="oldpws_verify">
            </el-input>
            <img v-if="ifeys1" @click="ifeys1 = !ifeys1" src="https://newstore.vynior.com/Outlineeyes.png" alt="" />
            <img v-if="!ifeys1" @click="ifeys1 = !ifeys1" src="https://newstore.vynior.com/Outlineeye.png" alt="" />

            <!-- <input type="password" placeholder="请输入旧密码" class="psw1" v-model="old_psw" /> -->
          </div>
          <div class="line3">
            <span>{{ $t(`message.Header.NewPassword`) }}</span>
            <el-input
              :placeholder="$t(`message.Header.EnterNewPWD`)"
              v-model="psw_one"
              class="input_password"
              @focus="pswTips = true"
              @blur="pswTips = false"
              :type="ifeys2 ? 'password' : 'text'"></el-input>
            <!-- <input type="password" placeholder="请输入新密码" class="psw1" v-model="psw_one" /> -->
            <img v-if="ifeys2" @click="ifeys2 = !ifeys2" src="https://newstore.vynior.com/Outlineeyes.png" alt="" />
            <img v-if="!ifeys2" @click="ifeys2 = !ifeys2" src="https://newstore.vynior.com/Outlineeye.png" alt="" />
          </div>

          <!-- <div class="line4">
            <span>密码长度8位，不可包含账号，必须包含字母、数字、符号两种</span>
          </div> -->
          <div class="line5">
            <span>{{ $t(`message.Header.Confirmpassword`) }}</span>
            <el-input
              :placeholder="$t(`message.Header.EnterNewPWDs`)"
              v-model="psw_two"
              class="input_password"
              :type="ifeys3 ? 'password' : 'text'"
              @blur="twopsw_verify"></el-input>
            <!-- <input type="password" placeholder="请再次输入新密码" class="psw2" v-model="" /> -->
            <img v-if="ifeys3" @click="ifeys3 = !ifeys3" src="https://newstore.vynior.com/Outlineeyes.png" alt="" />
            <img v-if="!ifeys3" @click="ifeys3 = !ifeys3" src="https://newstore.vynior.com/Outlineeye.png" alt="" />
          </div>

          <div class="line6" v-show="$store.state.lang == 'cn'">
            <img src="https://newstore.vynior.com/cancel2w.png" alt="" class="back_home" @click="change_ = 0" />
            <img src="https://newstore.vynior.com/queren.png" alt="" class="queren" @click="change_sure" />
          </div>

          <div class="line6" v-show="$store.state.lang == 'en'">
            <img src="https://newstore.vynior.com/backkks.png" alt="" class="back_home" @click="change_ = 0" />
            <img src="https://newstore.vynior.com/savesssave.png" alt="" class="queren" @click="change_sure" />
          </div>

          <!-- 要修改的提醒弹窗 -->
          <div class="password_tip" v-show="pswTips && $store.state.lang == 'cn'">
            <div class="text_content">
              8位密码长度 <br />
              至少包含数字、大小写字母及特殊字符中的两种 <br />
              不使用相同的数字或字符，如111111、aaaaaa<br />
              不使用连续升序或降序的数字或字母，如123456、abcdef<br />
              <!-- 不使用身份证后8位作为密码<br /> -->
              密码不包含账号
            </div>
          </div>
          <div class="password_tip" v-show="pswTips && $store.state.lang == 'en'">
            <div class="text_content">
              Password must<br />
              - Be at least 8 characters in length <br />
              - Include at least two of the following elements: <br />
              At least one letter (upper or lowercase)<br />
              At least one number<br />
              At least one special character (!"#$%&' ()*+,-./:;<=>?@[]^_` {|}~)<br />
              <!-- 不使用身份证后8位作为密码<br /> -->
              - Not include more than 2 identical characters (e.g. 11111,aaaaa) - Not include more than 2 consecutive 2 identical characters (e.g.
              123, abc) <br />
              - Not include your user name<br />
            </div>
          </div>
        </div>
      </div>
      <!-- 修改密码成功界面 -->
      <div class="change_success" v-show="success_">
        <div class="content2">
          <div class="line1">
            <span>{{ $t(`message.Project.Reset`) }}</span>
          </div>
          <div class="line2">
            <img src="https://newstore.vynior.com/success.png" alt="" class="susccess" />
            <span class="pswsuc">{{ $t(`message.Project.successfully`) }}</span>
            <span class="num_" v-show="$store.state.lang == 'cn'"
              ><span class="num">{{ count }}秒</span>后自动跳转至首页重新登录</span
            >
            <span class="num_" v-show="$store.state.lang == 'en'"
              >Return to login page in <span class="num">{{ count }} </span>seconds</span
            >
            <img src="https://newstore.vynior.com/backhome.png" alt="" class="back_home" @click="go_quit" v-show="$store.state.lang == 'cn'" />
            <img src="https://newstore.vynior.com/backLogin.png" alt="" class="back_home" @click="go_quit" v-show="$store.state.lang == 'en'" />
          </div>
        </div>
      </div>
    </div>

    <HelpCenter :helpDialogRoles="helpDialogRole" v-if="helpDialogRole" @unloding1="unloding1"></HelpCenter>
  </div>
</template>

<script>
import { message } from '@/utils/resetMessage';
import HelpCenter from '@/components/HelpCenters.vue';
import { HttpClient } from '@/utils/http';
export default {
  name: 'Header',
  data() {
    return {
      pswTips: false,
      en: 'en',
      cn: 'cn',
      helpDialogRole: false, //帮助中心的显示与隐藏
      HelpOperation: false, // 操作指引的显示与隐藏
      currentHelp: 0, //帮助中心显示百分线
      timer: null, //设置计时器,
      // 控制眼睛的变化
      ifeys1: true, //第一组眼睛的变化  旧密码
      ifeys2: false, //第二组眼睛的变化  新密码
      ifeys3: false, //第三组眼睛的变化    确认新密码
      ifeysone: false, //获得焦点出现眼睛
      ifeytwo: false,
      ifeysthress: false,
      count: '',
      res_psw: '',
      old_psw: '', //旧密码
      psw_one: '', //新密码
      psw_two: '', //确认密码
      success_: 0, //修改密码show
      change_: 0, //修改密码show
      show_choose: 0, //控制产品预测的显示隐藏
      newProject: [],
      cz_arr: { cz1: '项目信息修改', cz2: '预测', cz3: '导出', cz4: '删除' },
      myProject: [], // 我的项目--已有项目数据
      userName: this.user_name,
      focus: [0, 0, 0, 0, 0],
    };
  },
  components: { HelpCenter },
  props: {
    page_id: {
      type: String,
      // required: true,
      default: '/itemanalysis',
    },
    user_name: {
      type: String,
      default: '',
    },
  },
  props: ['info'],
  watch: {
    info() {
      console.log(this.info, 'info监听');
    },
    success_: {
      handler(newv) {
        if (newv == true) {
          console.log(newv);
          this.num_reduce();
        }
      },
    },
  },
  methods: {
    // 帮助中心子组件传给本组件
    unloding1(e) {
      console.log('触发父元素');
      this.$on(e, (this.helpDialogRole = false));
    },
    //  操作指引的显示关闭
    unloding2(e) {
      console.log('触发父元素');
      this.$on(e, (this.HelpOperation = false));
    },
    // 计时关闭
    num_reduce() {
      // 60s倒计时
      let TIME_COUNT = 5;
      if (!this.timer) {
        this.count = TIME_COUNT;
        // this.isGrey = true;
        // this.show = false;
        // this.dis = true;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else if (this.count == 0) {
            console.log('00000000');
            this.success_ = 0;
            clearInterval(this.timer);
            this.timer = null;
            this.go_quit();
          }
        }, 1000);
      }
    },
    //点击修改密码
    change_mm() {
      this.change_ = 1;
    },
    //修改密码 确认按钮
    async change_sure() {
      await HttpClient.post('subuser/oldPasswordVerify', {
        oldPassword: this.old_psw,
        id: this.$store.state.userid,
      }).then((res) => {
        console.log(res);
        this.res_psw = res.status;
        console.log(this.res_psw);
      });

      if (this.res_psw == 0) {
        if (this.psw_one != this.psw_two) {
          message({
            showClose: true,
            message: this.$t(`message.Header.Passwordsmatch`),
            type: 'warning',
          });
        } else {
          // var reg = new RegExp(/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,}$/);

          // var reg = new RegExp(/^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,30}$/);

          var reg = new RegExp(/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9a-zA-Z-`=\[\];',.~!@#$%^&*()_+|{}:"?]{8,30}$/);

          if (!reg.test(this.psw_one)) {
            message({
              showClose: true,
              message: `message.Header.Passwordmust`,
              type: 'warning',
            });
          } else {
            console.log('符合');
            this.change_ = 0;
            this.success_ = 1;
            await HttpClient.post('subuser/update', {
              type: '修改密码',
              updates: {
                _id: this.$store.state.userid,
                password: this.psw_one,
              },
            }).then((res) => {
              console.log(res);
            });
          }
        }
      } else {
        message({
          showClose: true,
          message: this.$t(`message.Header.passwordincorrect`),
          type: 'warning',
        });
      }
    },

    // 控制修改密码隐藏
    change_psw() {
      console.log(this.height);
      this.change_ = !this.change_;
    },

    async IsActive(val) {
      let _this = this;
      console.log(val, 'val');
      _this.focus.splice(val, 1, '1');

      if (val == 2) {
        console.log(2222);
        _this.$router.push({ path: '/itemAnalysis' });
      } else if (val == 1) {
        console.log(1111);
        _this.$router.push({ path: '/myproject' });
      } else if (val == 3) {
        console.log(3333);
        _this.$router.push({ path: '/RoughSketch' });
      } else if (val == 5) {
        console.log(555);
        _this.$router.push({ path: '/contrast' });
      } else if (val == 4) {
        console.log(444);
        localStorage.removeItem('Gname');
        localStorage.removeItem('nameARR');
        localStorage.removeItem('GGvalue');
        localStorage.removeItem('YYvalue');

        localStorage.removeItem('nameGG'); //雷达 名字

        localStorage.removeItem('GG'); //雷达
        localStorage.removeItem('YY'); //预测
        localStorage.removeItem('GGE'); //雷达  echarts
        localStorage.removeItem('YYE'); //预测  echarts
        localStorage.removeItem('val');
        localStorage.removeItem('VALs');

        localStorage.removeItem('shows', JSON.stringify(this.barPRED)); //状态管理
        this.$router.push({ path: '/history' });
      } else if (val == 8) {
        console.log(88888);
        this.$router.push({ path: '/importanceAnalysis' });
      } else if (val == 0) {
        this.$router.push({ path: '/home' });
      } else if (val == 9) {
        console.log(9999);
        this.$router.push({ path: '/Acategory' });
      } else if (val == 7) {
        console.log(777);
        this.$router.push({ path: '/Characteristic' });
      }

      // this.$set(this.focus, val, 1);
      console.log(this.focus, 'focus');
    },

    // // 品类分析跳转
    // async toActive(val){
    //   if(val==1){
    //     this.$router.push('/RoughSketch')
    //   }else if (val == 4) {
    //     this.$router.push('/importanceAnalysis')
    //   }
    // },
    show_chooses() {
      this.show_choose = !this.show_choose;
    },
    // go_quit退出登录
    go_quit() {
      if (this.$route.path != '/home') {
        this.$router.push({ path: '/home' });
        this.Quit_id.quit();
      } else {
        this.Quit_id.quit();
        location.reload();
      }
    },
    // 旧密码验证
    async oldpws_verify() {
      await HttpClient.post('subuser/oldPasswordVerify', {
        oldPassword: this.old_psw,
        id: this.$store.state.userid,
      }).then((res) => {
        this.ifeysone = false;
        console.log(res);
        this.res_psw = res.status;
        console.log(this.res_psw);
      });
      if (this.res_psw !== 0) {
        message({
          showClose: true,
          message: this.$t(`message.Header.passwordincorrect`),
          type: 'warning',
        });
      }
    },
    // 两次的密码验证
    twopsw_verify() {
      if (this.psw_one != this.psw_two) {
        message({
          showClose: true,
          message: this.$t(`message.Header.Passwordsmatch`),
          type: 'warning',
        });
      } else {
        // var reg = new RegExp(/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,}$/);

        // var reg = new RegExp(/^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,30}$/);

        var reg = new RegExp(/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9a-zA-Z-`=\[\];',.~!@#$%^&*()_+|{}:"?]{8,30}$/);

        if (!reg.test(this.psw_one)) {
          message({
            showClose: true,
            message: this.$t(`message.Header.Passwordmust`),
            type: 'warning',
          });
        } else {
          console.log('符合');
        }
      }
    },
    //切换语言
    langChange(val) {
      console.log(val);
      localStorage.setItem('lang', val);
      this.$i18n.locale = val;
      this.$store.commit('$_changeLang', val);
      console.log('改变了Lang');
      if (val == 'cn') {
        this.changeLanguage = false;
      } else {
        this.changeLanguage = true;
      }
    },
    //判断是否有localstorage
  },
  mounted() {
    this.$EventBus.$on('Toitem', (data) => {
      console.log('data :>> ', data);
      // if (data == 'toItem') {
      this.IsActive(2);
      // }
      console.log('this.focus :>> ', this.focus);
    });
    console.log(this.page_id);
    if (this.$route.path == this.page_id) {
      this.$set(this.focus, 2, 1);
    }

    // 接受名字

    // this.userName = this.user_name;
    this.userName = JSON.parse(localStorage.getItem('user_name'));
    console.log('this.$router.path :>> ', this.$router.path);
    // 根据页面地址去控制头部导航栏高亮
    if (this.$route.path == '/myproject') {
      this.$set(this.focus, 1, 1);
    } else if (this.$route.path == '/itemAnalysis') {
      this.$set(this.focus, 2, 1);
    } else if (this.$route.path == '/contrast') {
      this.$set(this.focus, 2, 1);
    } else if (this.$route.path == '/history') {
      this.$set(this.focus, 4, 1);
    } else if (this.$route.path == '/home') {
      this.$set(this.focus, 0, 1);
    } else if (this.$route.path == '/Acategory') {
      this.$set(this.focus, 3, 1);
    } else if (this.$route.path == '/importanceAnalysis') {
      this.$set(this.focus, 3, 1);
    } else if (this.$route.path == '/Characteristic') {
      this.$set(this.focus, 3, 1);
    } else if (this.$route.path == '/RoughSketch') {
      this.$set(this.focus, 3, 1);
    }
  },
  created() {
    this.$EventBus.$on('focusValue', (data) => {
      console.log('我接收到了 :>> ', '我接收到了', data);
      this.focus = data;
    });
    // location.reload();
    // this.$EventBus.$off("router_change")
    this.$EventBus.$on('router_change', (data) => {
      this.IsActive(data);
      console.log(data, 'data');
    });
    console.log('this.$route.path :>> ', this.$route.path);

    if (this.$route.path != '/history') {
      this.$store.commit('$_setForcast_data', []);
      this.$store.commit('$_changePanel_data', []);
      this.$store.commit('$_changeProductNameList', []);
      this.$store.commit('$_setForcast_data', []);
      this.$store.commit('$_changeLikeProductList', []);
      this.$store.commit('$_changeLikeNameList', []);
      this.$store.commit('$_changePanelNameList', []);
    }
    //切换语言
    // console.log(localStorage.lang);
    // if (localStorage.lang == null || localStorage.lang == 'undefined') {
    //   this.$store.commit('$_changeLang', 'en');
    // } else {
    //   this.$store.commit('$_changeLang', localStorage.lang);
    // }
    // console.log('改变了Lang');
    // // that.selectValue = localStorage.lang == undefined ? 'cn' : localStorage.lang;
    // if (localStorage.lang == 'en' || localStorage.lang == 'undefined' || localStorage.lang == null) {
    //   this.changeLanguage = true;
    // } else {
    //   this.changeLanguage = false;
    // }

    this.$store.commit('$_changeLang', localStorage.lang);
  },

  beforeDestroy() {},
};
</script>

<style lang="less" scoped>
@import url('../style/Header.less');
/deep/.el-message {
  z-index: 999999 !important;
}
</style>
